var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column pa-2"},[_c('v-sheet',{attrs:{"color":"white","elevation":"0","height":"90%","width":"100%"}},[_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('div',{staticClass:"text-center headline font-weight-bold pa-3 cursor"},[_vm._v(" "+_vm._s(("" + (_vm.getName())))+" ")])]),_c('v-col',{attrs:{"cols":"1"}},[(this.isGroupQuery)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.showDetails = !_vm.showDetails}}}):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-responsive',{staticClass:"fit-content",attrs:{"max-height":"70vh"}},[_c('v-list',{staticClass:"chat-content",attrs:{"width":"100%"}},[_vm._l((_vm.chats),function(chat,index){return [_c('div',{key:chat.id},[(!chat.media.length)?_c('ChatText',{attrs:{"message":chat}}):_c('ChatMedia',{attrs:{"message":chat}})],1),(
              index == _vm.chats.length - 1 && index >= 9 && _vm.fetchMore && !_vm.loading
            )?_c('div',{key:((chat.id) + "'--'" + index)},[_c('v-list-item',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:({
                handler: _vm.onIntersect,
                options: {
                  threshold: [1, 1, 1],
                },
              }),expression:"{\n                handler: onIntersect,\n                options: {\n                  threshold: [1, 1, 1],\n                },\n              }",modifiers:{"quiet":true}}]},[_c('v-list-item-content',{staticClass:"text-center"},[_c('v-card',{staticClass:"mx-auto",attrs:{"flat":""}},[_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.fetchingMore}},[_vm._v("Loading...")])],1)],1)],1)],1):_vm._e()]})],2)],1)],1),_c('div',{staticClass:"flex-wrap d-flex text-submit"},[_c('div',[_c('v-file-input',{key:_vm.counter,attrs:{"hide-input":"","append-icon":"mdiPaperclipPlus","accept":"image/*, application/pdf,","solo":""},on:{"change":_vm.onChangeFile}})],1),_c('v-textarea',{staticClass:"mx-1",attrs:{"full-width":"","dense":"","hide-details":"","rows":"1","row-height":"15","outlined":"","label":"Enter message here"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary"},on:{"click":_vm.submit}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-send ")]),_vm._v(" Send ")],1)],1),_c('v-navigation-drawer',{key:_vm.counter,attrs:{"right":"","app":"","temporary":"","width":this.$vuetify.breakpoint.smAndDown ? "100vw" : "350px"},model:{value:(_vm.showDetails),callback:function ($$v) {_vm.showDetails=$$v},expression:"showDetails"}},[_c('GroupDetails',{attrs:{"name":_vm.getName()},on:{"close":function($event){_vm.showDetails = !_vm.showDetails},"save":function($event){_vm.showDetails = !_vm.showDetails}}})],1),_c('v-dialog',{attrs:{"width":"600px"},model:{value:(_vm.filePreview),callback:function ($$v) {_vm.filePreview=$$v},expression:"filePreview"}},[_c('PreviewFile',{attrs:{"url":_vm.url,"documentName":_vm.documentName,"isImage":_vm.isIamge,"saving":_vm.savingFile,"action":"Send"},on:{"close":_vm.closePreview,"send":_vm.uploadFile}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }