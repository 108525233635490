














































































































import { ChatList, Message, MessageGroup } from "@/api";
import { selfData } from "@/common/role_utils";
import { socket } from "@/tool/socket.io";
import { Component, Vue, Watch } from "vue-property-decorator";
import ChatText from "@/views/chat/ChatText.vue";
import ChatMedia from "@/views/chat/ChatMedia.vue";
import PreviewFile from "@/views/chat/PreviewFile.vue";
import GroupDetails from "@/views/chat/GroupDetails.vue";
import _ from "lodash";
import { maxFileSize } from "@/tool/validation";

@Component({
  components: {
    ChatText,
    GroupDetails,
    PreviewFile,
    ChatMedia,
  },
})
export default class Chat extends Vue {
  @Watch("selectedEmployee", { immediate: true, deep: true })
  onEmployeeChange(newVal: ChatList) {
    this.offset = 0;
    if (!this.isGroupQuery && newVal?.employeeId)
      this.fetchChatHistory(newVal.employeeId, "fetchChats");
  }

  @Watch("selectedGroup", { immediate: true, deep: true })
  onGroupChange(newVal: MessageGroup) {
    this.offset = 0;
    if (this.isGroupQuery && newVal?.id)
      this.fetchChatHistory(newVal.id, "fetchChats");
  }

  message = "";
  counter = 0;
  offset = 0;
  loading = false;
  savingFile = false;
  showDetails = false;
  filePreview = false;
  isIamge = false;
  fetchingMore = false;
  url: string | ArrayBuffer | null | undefined = "";
  document: File | null = null;
  documentName = "";

  get selectedEmployee() {
    return this.$store.getters["chat/selectedEmployee"];
  }

  get selectedGroup() {
    return this.$store.getters["chat/selectedGroup"];
  }

  get chats() {
    const list = this.$store.getters["chat/chats"] as Message[];
    _.forEach(list || [], item => {
      if (item.from.id != selfData().id && !item.readAt) {
        const payload = {
          messageId: item.id,
          employeeId: selfData().id,
        };
        socket.emit("/read-message", payload);
        setTimeout(this.fetchUnreadCount, 2000);
      }
    });
    return list || [];
  }

  get isGroupQuery() {
    return this.$route.query?.isGroup;
  }

  get fetchMore() {
    return this.$store.getters["chat/fetchMore"];
  }

  async fetchChatHistory(id: number, actionMethod: string) {
    this.loading = true;
    if (this.isGroupQuery) {
      await this.$store.dispatch(`chat/${actionMethod}`, {
        from: selfData().id,
        group: id || parseInt(this.$route.params.id),
        offset: this.offset,
      });
    } else {
      await this.$store.dispatch(`chat/${actionMethod}`, {
        from: selfData().id,
        to: id || parseInt(this.$route.params.id),
        offset: this.offset,
      });
    }
    this.loading = false;
  }

  clearPayload() {
    this.message = "";
    this.document = null;
  }

  fetchUnreadCount() {
    this.$store.dispatch("chat/fetchUnreadMessageCount");
  }

  getName() {
    if (this.isGroupQuery) return this.selectedGroup?.name || "";
    else return `${this.selectedEmployee?.name || ""}`;
  }

  submit() {
    if (this.message.trim() == "") return;
    const payload = {
      id: 0,
      message: this.message,
      to: this.isGroupQuery ? undefined : this.selectedEmployee?.employeeId,
      from: selfData().id,
      companyId: selfData().companyId,
      media: "",
      group: this.isGroupQuery ? this.selectedGroup?.id : undefined,
    };
    socket.emit("/message", payload);
    this.clearPayload();
  }

  onChangeFile(file: File) {
    this.counter++;
    if (file) {
      const checkSize = maxFileSize(file, 5);
      if (checkSize !== true) {
        this.$store.dispatch("alert/error", checkSize);
        return;
      }

      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event) {
          this.documentName = file.name;
          this.url = URL.createObjectURL(file);
        }
      };
      reader.readAsArrayBuffer(file);
      this.document = file;
    }

    this.isIamge = file.type.includes("image");
    this.filePreview = true;
  }

  async uploadFile() {
    this.savingFile = true;
    const payload = {
      id: 0,
      message: this.message,
      to: this.isGroupQuery ? undefined : this.selectedEmployee?.employeeId,
      group: this.isGroupQuery ? this.selectedGroup?.id : undefined,
      from: selfData().id,
      media: "",
      companyId: selfData().companyId,
      file: this.document,
    };

    await this.$store.dispatch("chat/messageWithMedia", payload);

    this.savingFile = false;
    this.filePreview = false;
    this.clearPayload();
    this.closePreview();
  }

  closePreview() {
    this.filePreview = false;
    this.counter++;
  }

  async onIntersect() {
    if (this.fetchingMore || this.loading || !this.fetchMore) return;
    this.fetchingMore = true;
    this.offset++;
    alert(this.offset);
    await this.fetchChatHistory(
      this.isGroupQuery
        ? this.selectedGroup?.id
        : this.selectedEmployee?.employeeId,
      "fetchMoreChats",
    );
    this.fetchingMore = false;
  }
}
