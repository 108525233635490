/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MessageFile = {
    properties: {
        id: {
    type: 'number',
},
        message: {
    type: 'string',
    isRequired: true,
},
        to: {
    type: 'number',
},
        group: {
    type: 'number',
},
        from: {
    type: 'number',
    isRequired: true,
},
        media: {
    type: 'string',
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        file: {
    type: 'string',
    isRequired: true,
    format: 'binary',
},
    },
};