/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChatLists } from '../models/ChatLists';
import type { GroupAdmin } from '../models/GroupAdmin';
import type { MessageGroup } from '../models/MessageGroup';
import type { MessageGroupDetails } from '../models/MessageGroupDetails';
import type { MessageGroupList } from '../models/MessageGroupList';
import type { MessageGroupRequest } from '../models/MessageGroupRequest';
import type { Messages } from '../models/Messages';
import type { UnreadCount } from '../models/UnreadCount';
import { request as __request } from '../core/request';

export class MessageService {

    /**
     * Get Messages between users
     * @returns Messages Messages between users
     * @throws ApiError
     */
    public static async getMessages({
companyId,
from,
offset,
to,
group,
}: {
/** The companyId **/
companyId: number,
from: number,
offset: number,
to?: number,
group?: number,
}): Promise<Messages> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/message`,
            query: {
                'from': from,
                'offset': offset,
                'to': to,
                'group': group,
            },
        });
        return result.body;
    }

    /**
     * upload media on message
     * @returns any message sent with media
     * @throws ApiError
     */
    public static async uploadMediaOnMessage({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: any,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/message/media`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get Message Group List user is in
     * @returns MessageGroupList List of involve group list of user
     * @throws ApiError
     */
    public static async getMessagesGroupList({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<MessageGroupList> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/message/group/list`,
        });
        return result.body;
    }

    /**
     * Get Message Group List user is in
     * @returns MessageGroupDetails Details of message group
     * @throws ApiError
     */
    public static async getMessagesGroupDetails({
companyId,
group,
}: {
/** The companyId **/
companyId: number,
group: number,
}): Promise<MessageGroupDetails> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/message/group/details`,
            query: {
                'group': group,
            },
        });
        return result.body;
    }

    /**
     * Update message group detail
     * @returns MessageGroup update message group
     * @throws ApiError
     */
    public static async updateMessageGroupDetail({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: MessageGroup,
}): Promise<MessageGroup> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/message/group`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * add message group detail
     * @returns MessageGroup add message group
     * @throws ApiError
     */
    public static async addMessageGroupDetail({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: MessageGroupRequest,
}): Promise<MessageGroup> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/message/group`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * delete message group detail
     * @returns MessageGroup delete message group
     * @throws ApiError
     */
    public static async deleteMessageGroupDetail({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: MessageGroup,
}): Promise<MessageGroup> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/message/group`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * add message group participants
     * @returns any participants added successfully
     * @throws ApiError
     */
    public static async addMessageGroupParticipants({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: MessageGroupRequest,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/message/group/participants`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * remove message group participant
     * @returns any remove message group participant
     * @throws ApiError
     */
    public static async removeMessageGroupParticipant({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: MessageGroupRequest,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/message/group/participants`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get Chat List of the user
     * @returns ChatLists List of involve chat of user
     * @throws ApiError
     */
    public static async getMessagesChatList({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<ChatLists> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/message/chat-list`,
        });
        return result.body;
    }

    /**
     * Get unread message count
     * @returns UnreadCount unread message count
     * @throws ApiError
     */
    public static async getUnreadMessagesCount({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<UnreadCount> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/message/unread-count`,
        });
        return result.body;
    }

    /**
     * Add group admin
     * @returns any add group admin
     * @throws ApiError
     */
    public static async addGroupAdmin({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: GroupAdmin,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/message/group/admin`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Remove group admin
     * @returns any remove group admin
     * @throws ApiError
     */
    public static async removeGroupAdmin({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: GroupAdmin,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/message/group/admin`,
            body: requestBody,
        });
        return result.body;
    }

}